<template>
  <DialogFormWrapper>
    <template #form>
      <AddressForm
        ref="newAddressForm"
        class="pt-5"
        @addNewAddress="submitAddressForm"
      />
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="form-action"
        :loading="isProcessing"
        @click="$refs.newAddressForm.submitAddressForm()"
      >
        Dodaj lokalizację
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import AddressForm from './Partials/AddressForm'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    AddressForm,
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  computed: {
    ...mapState({
      client: state => state.layout.dialog.item,
      isProcessing: state => state.addresses.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewAddress: 'addresses/addNewItem'
    }),
    submitAddressForm (params) {
      params = { ...params, clientId: this.client.id }
      this.addNewAddress({ params })
        .then(() => {
          this.afterFormSubmitted('Dodano nową lokalizację')
        })
    }
  }
}
</script>
